<script setup>
import { defineExpose, onMounted, ref } from 'vue'
import FilePond from '@/apps/pharmasan/compras/logistica/views/cargue-facturas/components/uploadFilePond/index.vue'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import Icon from '@/components/Icon/index.vue'
import { FilterMatchMode } from 'primevue/api'

const toast = useToast()
const displayModal = ref(false)
const items = ref([])
const attachFile = ref(null)
const listResults = ref([])
const columns = ref([])
const invoiceNumber = ref('')
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Columnas seleccionadas para mostrar en la tabla y procesar
const selectedColumns = [
  'PRODUCTO',
  'NOMBRE_PRODUCTO',
  'LOTE_PRODUCTO',
  'FEC_VEN_LOTE',
  'TD',
  'DOCUMENTO'
]

// Funcion para abrir el modal y cargar la información
const openModal = async (data) => {
  displayModal.value = true
  items.value = data.Items
  invoiceNumber.value = data.InvoiceNumber
  console.log('data -->', data)
}

// Función para cerrar el modal y limpiar la información
const closeModal = async () => {
  displayModal.value = false
  clearInfo()
}

/* Capturar el adjunto de filepond */
const onCaptureFile = (files) => {
  console.log('files --> ', files)
  attachFile.value = files
}

// Función principal para cargar y procesar el archivo Excel
const handleFileUpload = async () => {
  if (attachFile.value) {
    const reader = new FileReader()

    reader.onload = async (event) => {
      // Procesa el archivo Excel
      const data = new Uint8Array(event.target.result)
      const workbook = XLSX.read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const dataExcel = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true })

      // Extrae los encabezados de la cuarta fila (índice 3)
      const headers = dataExcel[3]

      // Configura las columnas para la tabla de PrimeVue
      columns.value = [
        ...headers
          .filter((header) => selectedColumns.includes(header))
          .map((header) => ({
            field: header,
            header: header
          })),
        {
          field: 'isValid',
          header: 'ES VÁLIDO?'
        }
      ]

      // Procesa los datos del Excel, comenzando desde la quinta fila (índice 4)
      listResults.value = dataExcel.slice(4).map((row) => {
        const obj = {}
        selectedColumns.forEach((header) => {
          const index = headers.indexOf(header)
          if (index !== -1) {
            obj[header] = row[index]
          }
        })
        // Validar si el número de factura coincide
        const rowInvoiceNumber = `${obj.TD}${obj.DOCUMENTO}`
        obj.isValid = rowInvoiceNumber === invoiceNumber.value
        return obj
      })
    }
    reader.readAsArrayBuffer(attachFile.value)
  } else {
    toast.add({
      severity: 'error',
      summary: 'Validación',
      detail: 'Por favor seleccione un archivo',
      life: 3000
    })
  }
}

/* Limpiando el attach cuando se remueve o reemplaza el archivo */
const onRemoveFile = (files) => {
  clearInfo()
}

// Función para limpiar toda la información
const clearInfo = () => {
  attachFile.value = null
  listResults.value = []
  columns.value = []
}

// Función principal para procesar y combinar los datos al hacer submit
const onSubmit = () => {
  // Itera sobre cada item y actualiza su información con los datos del Excel
  const updatedItems = items.value.map((item) => {
    // Busca un resultado que coincida con el código del item y sea válido
    const matchingResult = listResults.value.find(
      (result) => result.PRODUCTO === item.CodigoDisfarma && result.isValid
    )
    if (matchingResult) {
      // Si encuentra una coincidencia válida, actualiza la información de los lotes
      item.BatchNumbers = item.BatchNumbers.map((batch) => ({
        ...batch,
        ExpDate: matchingResult.FEC_VEN_LOTE,
        DistNumber: matchingResult.LOTE_PRODUCTO
      }))
    }
    return item
  })

  console.log('Updated items:', updatedItems)
  // Mostrar un sweetalert notificando que se asociaron los lotes y cerrar el modal
  Swal.fire({
    icon: 'success',
    title: '¡Éxito!',
    text: 'Los lotes se han asociado correctamente.',
    confirmButtonText: 'Aceptar'
  }).then(() => {
    clearInfo()
    displayModal.value = false
  })
}

// Expone la función openModal para que pueda ser llamada desde el componente padre
defineExpose({
  openModal
})

// Hook onMounted
onMounted(() => {
  console.log('onMounted, modal cargue de lotes disfarma...')
})
</script>

<template>
  <Toast />
  <Dialog
    v-model:visible="displayModal"
    modal
    :closable="false"
    maximizable
    :closeOnEscape="false"
    :breakpoints="{ '960px': '95vw' }"
    :style="{ width: '60vw' }"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Cargar Lotes Disfarma` }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="grid gap-4 grid-cols-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="p-4 flex flex-col" style="height: 11rem">
          <FilePond ref="pondFile" @captureFile="onCaptureFile" @removeFile="onRemoveFile" />
          <Button
            label="Validar importación"
            icon="pi pi-upload"
            severity="contrast"
            class="p-button-sm"
            @click="handleFileUpload"
          />
        </div>
      </div>

      <!-- Tabla de resultados -->
      <div class="intro-y col-span-12 lg:col-span-12">
        <DataTable
          ref="dt"
          :value="listResults"
          :columns="columns"
          scrollable
          scrollHeight="400px"
          :filters="filters"
          responsive-layout="scroll"
          class="p-datatable-sm text-xs"
        >
          <template #header>
            <div
              class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between items-center"
            >
              <h3
                class="p-mb-2 p-m-md-0 p-as-md-center text-lg"
                :class="{ 'text-green-800': listResults.length > 0 }"
              >
                Listado de lotes a importar
              </h3>
              <span class="text-green-800 text-base">Total: {{ listResults.length }}</span>
              <IconField iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </IconField>
            </div>
          </template>

          <template #empty>
            <div class="flex flex-col items-center justify-center" style="height: 380px">
              <Icon
                icon="flat-color-icons:fine-print"
                class="text-gray-200"
                style="font-size: 15rem; opacity: 0.3"
              />
              <span class="text-gray-400 text-2xl"
                >Esperando por la validación del archivo a importar</span
              >
              <span class="text-gray-300"
                >Cargue e importe el archivo en excel para visualizar la información</span
              >
            </div>
          </template>
          <template #loading> Cargando la información, por favor espere... </template>
          <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header">
            <template #body="slotProps" v-if="col.field === 'isValid'">
              <span
                :class="{
                  'text-green-500': slotProps.data.isValid,
                  'text-red-500': !slotProps.data.isValid
                }"
              >
                {{ slotProps.data.isValid ? 'Sí' : 'No' }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <!-- Footer del modal -->
    <template #footer>
      <Button
        label="Cerrar"
        icon="pi pi-times"
        @click="closeModal"
        severity="secondary"
        class="p-button-xs"
        autofocus
      />
      <Button
        label="Asociar Lotes"
        v-if="listResults.length > 0"
        severity="success"
        @click="onSubmit"
        class="p-button-xs"
      />
    </template>
  </Dialog>
</template>

<style scoped></style>
