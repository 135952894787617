<script setup>
import { onMounted, ref, defineExpose, defineEmits } from 'vue'
import { useToast } from 'primevue/usetoast'
// import { useInfoCargueFacturasStore } from '../../../store/infoCargueFacturas.store'

// const infoCargueFacturasStore = useInfoCargueFacturasStore()
const toast = useToast()
const visible = ref(false)
const dataItemPedido = ref([])
const dataItemFactura = ref([])
const selectedProduct = ref(null)
const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
const sidebarStyle = ref(window.innerWidth > 1024 ? 'width: 50vw' : '')
const emit = defineEmits(['emitUpdateLineFactura'])

// Abrir el sidebar
const openSidebar = async (dataPedido, dataFactura) => {
  visible.value = true
  dataItemPedido.value = dataPedido.filter(item => !item.isInPedido)
  dataItemFactura.value = dataFactura
  selectedProduct.value = null
}

// Relacionar la factura con el pedido
const submitRelacionarFactura = async () => {
  if (!selectedProduct.value) {
    toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor selecciona una línea del pedido.', life: 2000 })
    return
  }

  // Emitir evento para actualizar la línea de la factura
  emit('emitUpdateLineFactura', {
    selectedProduct: selectedProduct.value,
    dataLineFactura: dataItemFactura.value
  })

  // Cerrar el sidebar
  visible.value = false
}

// Cancelar la relacion de la factura con el pedido
const cancelRelacionarFactura = async () => {
  emit('emitCancelLineFactura', {
    dataLineFactura: dataItemFactura.value,
    isCancel: true
  })
  visible.value = false
}

// Evento cuando se redimensiona la ventana
window.addEventListener('resize', evt => {
  sidebarPosition.value = window.innerWidth > 1024 ? 'right' : 'full'
  sidebarClass.value = window.innerWidth > 1024 ? 'p-sidebar-md' : ''
  sidebarStyle.value = window.innerWidth > 1024 ? 'width: 50vw' : ''
})

// Se expone la funcion para abrir el sidebar, para que pueda ser llamado desde el componente padre
defineExpose({
  openSidebar
})

// On mounted
onMounted(() => {
  console.log('SidebarItemsPedido')
})
</script>

<template>
  <div class="card flex justify-content-center">
    <Sidebar
      v-model:visible="visible"
      :position="sidebarPosition"
      :baseZIndex="1000"
      :showCloseIcon="false"
      :dismissable="false"
      :class="sidebarClass"
      :style="sidebarStyle"
      :pt="{
        header: {
          style: 'display: flex; align-items: center; justify-content: space-between;'
        }
      }"
    >
      <p class="font-bold mb-4">
        Por favor selecciona la línea del pedido que deseas relacionar con la factura.
      </p>

      <p class="mb-2">
        Las siguientes líneas del pedido tienen la misma agrupación y bodega del artículo seleccionado en la factura:
      </p>

      <DataTable
        v-model:selection="selectedProduct"
        :value="dataItemPedido"
        dataKey="LineNum"
        tableStyle="min-width: 50rem"
        class="p-datatable-sm text-xs"
      >
        <Column selectionMode="single" headerStyle="width: 3rem"></Column>
        <Column field="DocNum" header="Pedido">
          <template #body="{ data }">
            <div class="flex flex-col">
              <span><strong>#: </strong>{{ data.DocNum }}</span>
              <span><strong>Línea: </strong>{{ data.LineNum }}</span>
            </div>
          </template>
        </Column>
        <Column field="ItemCode" header="Descripción Artículo">
          <template #body="{ data }">
            <div class="flex flex-col">
              <span><strong>Código Mx: </strong>{{ data.ItemCode }}</span>
              <span><strong>Nombre Mx: </strong>{{ data.ItemName }}</span>
            </div>
          </template>
        </Column>
        <Column field="Cum" header="Cum/Agrupación">
          <template #body="{ data }">
            <div class="flex flex-col">
              <span><strong>Cod. Cum: </strong>{{ data.Cum }}</span>
              <span><strong>Agrupación: </strong>{{ data.Agrupacion }}</span>
            </div>
          </template>
        </Column>
        <Column field="Quantity" header="Cantidades">
          <template #body="{ data }">
            <div class="flex flex-col">
              <span><strong>Solicitada: </strong>{{ parseInt(data.Quantity) }}</span>
              <span><strong>Pendiente: </strong>{{ parseInt(data.OpenQty) }}</span>
            </div>
          </template>
        </Column>
        <Column field="WhsCode" header="Bodega">
          <template #body="{ data }">
            <div class="flex flex-col">
              <span><strong>Cod: </strong>{{ data.WhsCode }}</span>
              <span><strong>Nombre: </strong>{{ data.WhsName }}</span>
            </div>
          </template>
        </Column>
      </DataTable>
      <!-- <pre>{{ JSON.stringify(dataItemPedido, null, '\t') }}</pre>-->

      <!-- Agregar al final del sidebar boton para relacionar la factura con el pedido -->
      <div class="flex flex-wrap items-end justify-end mt-6">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          size="small"
          severity="secondary"
          @click="cancelRelacionarFactura"
          class="mr-2"
        />
        <Button
          label="Relacionar Factura"
          icon="pi pi-check"
          size="small"
          severity="success"
          @click="submitRelacionarFactura"
        />
      </div>
    </Sidebar>
  </div>
</template>

<style scoped>

</style>
